
import { defineComponent, ref, computed } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { generalStore } from '@/store';
import SnoozePopup from '@/components/CrmCustomers/SnoozePopup.vue';
import { UserReminder } from '@/models/CrmCustomers';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import { useI18n } from 'vue-i18n';
import q from 'q';
import { CrmCustomerReminderSaveRequest } from '@/models/CrmCustomers';
import { ReminderSaveChanges } from '@/models/Enums';
import CrmCustomerChatMessagePopup from '@/components/CrmCustomers/CrmCustomerChatMessagePopup.vue';

export default defineComponent({
    components: {
        OverlayModal,
        SnoozePopup,
        CrmCustomerChatMessagePopup
    },
    name: 'RemindersSnoozePopup',
    async setup() {
        const { t } = useI18n();
        const modalCrmCustomerChatMessage = ref<InstanceType<typeof CrmCustomerChatMessagePopup>>();
        const reminders = computed<UserReminder[]>(() => generalStore.state.userReminders);
        const modalSnooze = ref<InstanceType<typeof SnoozePopup>>();
        const isOpen = computed<boolean>(() => generalStore.state.showRemindersSnoozePopup);
        function close() {
            generalStore.commit('showRemindersSnoozePopup', false);
        }
        async function dismissAll() {
            const swalAction = await swal.fire({
                icon: 'info',
                text: t('reminders-snooze-popup.alert.disable-all'),
                customClass: {
                    confirmButton: 'stop-sale-button popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeactivate'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalAction.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const reminderIds = reminders.value.map(x => x.id);
            const apiResult = await api.dismissAllUserReminders(reminderIds);
            if (apiResult.errorMessage || !apiResult.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage || 'No data'
                });
                return;
            }
            generalStore.commit('setUserRemindersNotActive', reminderIds);
            swal.close();
        }
        function openSnooze(reminderId: string, crmCustomerId: string) {
            modalSnooze.value?.open(reminderId, crmCustomerId);
        }
        async function disactivateOrActivateReminder(reminder: UserReminder, evt: Event) {
            evt.preventDefault();
            swal.showLoading();
            const newValue = !reminder.active;
            const apiRequest = new CrmCustomerReminderSaveRequest();
            apiRequest.id = reminder.id;
            apiRequest.active = newValue;
            apiRequest.crmCustomerId = reminder.crmCustomerId;
            apiRequest.changes = ReminderSaveChanges.Active;
            const response = await api.saveCrmCustomerReminder(apiRequest);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            generalStore.commit('setUserReminderNotActive', { reminderId: reminder.id, newValue });
            swal.close();
        }
        async function disactivateOrActivateReminderOnButtonClick(reminder: UserReminder) {
            swal.showLoading();
            const newValue = false;
            const apiRequest = new CrmCustomerReminderSaveRequest();
            apiRequest.id = reminder.id;
            apiRequest.active = newValue;
            apiRequest.crmCustomerId = reminder.crmCustomerId;
            apiRequest.changes = ReminderSaveChanges.Active;
            const response = await api.saveCrmCustomerReminder(apiRequest);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            generalStore.commit('setUserReminderNotActive', { reminderId: reminder.id, newValue });
            swal.close();
        }
        function openCrmCustomerChatMessageModal(crmCustomerId: string, crmCustomerFullName: string) {
            modalCrmCustomerChatMessage.value?.open(crmCustomerId, crmCustomerFullName);
        }

        return {
            isOpen,
            open,
            close,
            dismissAll,
            openSnooze,
            modalSnooze,
            reminders,
            disactivateOrActivateReminder,
            disactivateOrActivateReminderOnButtonClick,
            modalCrmCustomerChatMessage,
            openCrmCustomerChatMessageModal
        };
    }
});
