
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import RadioInput from '@/components/RadioInput.vue';
import { SnoozeTypes, SnoozePeriods, ToOptions } from '@/models/Enums';
import Multiselect from '@vueform/multiselect';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { generalStore } from '@/store';

export default defineComponent({
    components: {
        OverlayModal,
        RadioInput,
        Multiselect
    },
    name: 'RemindersSnoozePopup',
    async setup(props: any, { emit }: any) {
        const snoozeTypes = SnoozeTypes;
        const snoozeType = ref(SnoozeTypes.FiveMinutes);
        const snoozeTime = ref<number | string | null>(null);
        const isOpen = ref(false);
        const selectedPeriod = ref<null | SnoozePeriods>(SnoozePeriods.days);
        const periods = ToOptions(SnoozePeriods, 'snoozeType.');
        const reminderId = ref('');
        const crmCustomerId = ref('');
        function open(reminderIdProp: string, crmCustomerIdProp: string) {
            const elements = document.querySelectorAll('.was-validated');
            elements.forEach(element => {
                (element as HTMLElement).classList.remove('was-validated');
            });
            crmCustomerId.value = crmCustomerIdProp;
            reminderId.value = reminderIdProp;
            snoozeType.value = SnoozeTypes.FiveMinutes;
            snoozeTime.value = 5;
            selectedPeriod.value = SnoozePeriods.minutes;
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        function onSelectSnoozeType(type: SnoozeTypes) {
            switch (type) {
                case SnoozeTypes.FiveMinutes:
                    snoozeTime.value = 5;
                    break;
                case SnoozeTypes.OneHour:
                    snoozeTime.value = 60;
                    break;
                case SnoozeTypes.OneDay:
                    snoozeTime.value = 1440;
                    break;
                case SnoozeTypes.Custom:
                    snoozeTime.value = null;
                    break;
            }
        }
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            let minutes = Number(snoozeTime.value);
            if (snoozeType.value == SnoozeTypes.Custom) {
                switch (selectedPeriod.value) {
                    case SnoozePeriods.hours:
                        minutes = minutes * 60;
                        break;
                    case SnoozePeriods.days:
                        minutes = minutes * 1440;
                        break;
                }
            }
            const response = await api.snoozeReminder(crmCustomerId.value, reminderId.value, minutes);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            generalStore.commit('updateBySnooze', response.data);
            close();
        };

        return {
            isOpen,
            open,
            close,
            submitForm,
            onSelectSnoozeType,
            snoozeTime,
            snoozeType,
            snoozeTypes,
            periods,
            selectedPeriod,
            reminderId
        };
    }
});
