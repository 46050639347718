
import { api } from '@/services/Api';
import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
import { Notification, RepliedNotification } from '@/models/Interfaces';
import { NotificationType, OrderStatus, OrderStatusColors, ProductDetailSections } from '@/models/Enums';
import { generalStore } from '@/store';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import moment from 'moment';
import $ from 'jquery';
import useOrder from '@/modules/useOrder';
import { GetNotificationsResponse } from '@/models/ResponseInterfaces';

export default {
    props: {
        additionalClass: String
    },
    setup() {
        const productDetailSections = ProductDetailSections;
        const { t, locale } = useI18n();
        moment.locale(locale.value);
        const busy = ref(false);
        const hasNext = ref(true);
        const repliedMessagesNotification = ref<RepliedNotification[]>([]);
        const { removemillis } = useOrder();

        const isNotificationMenuExpand = computed(() => generalStore.getters.isNotificationOpened);

        const notifications = ref<Notification[]>([]);
        const notificationsCount = ref(0);

        const getCurrencySymbol = (id: string) => generalStore.getters.getCurrencySymbol(id);

        let getNewNotifications: () => void = () => {
            'funtion template for stupid composition api';
        };

        function pushNotifications(data: GetNotificationsResponse, lastId: string, fromId: string) {
            notificationsCount.value = data.notificationsCount;
            if (fromId) {
                notifications.value.push(...data.notifications);
                if (data.notifications.length) hasNext.value = true;
                else hasNext.value = false;
            } else if (lastId) {
                notifications.value.unshift(...data.notifications);
            } else {
                notifications.value = data.notifications;
            }
            if (data.reminders?.length > 0) {
                generalStore.commit('addUserReminders', data.reminders);
                generalStore.commit('showRemindersSnoozePopup', true);
            }
        }

        let notificationTimerId = 0;
        const getNotifications = async (lastId: string, fromId: string) => {
            busy.value = true;
            const response = await api.getProviderNotifications(lastId, fromId);
            busy.value = false;
            if (response.errorMessage) console.log(response.errorMessage);
            else if (response.data) {
                pushNotifications(response.data, lastId, fromId);
            }
            clearTimeout(notificationTimerId);
            notificationTimerId = window.setTimeout(getNewNotifications, 30 * 1000);
        };

        getNewNotifications = async () => {
            const lastId = notifications.value.length > 0 ? notifications.value[0].id : '';
            await getNotifications(lastId, '');
        };

        const getOldNotifications = async () => {
            const fromId = notifications.value.length > 0 ? notifications.value[notifications.value.length - 1].id : '';
            await getNotifications('', fromId);
        };

        const handleScroll = async () => {
            const notificationsList = document.getElementById('notifications-list');
            if (notificationsList) {
                const el = $(notificationsList);
                if (el.scrollTop() + el.innerHeight() >= el[0].scrollHeight - 1 && hasNext.value && !busy.value) {
                    await getOldNotifications();
                }
            }
        };

        const notificationMarkAsReaded = async (id: string, markAll: boolean) => {
            const response = await api.notificationMarkAsReaded(id, markAll);
            if (response.errorMessage) swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
            else if (response.data) {
                if (markAll) {
                    notifications.value = [];
                    notificationsCount.value = 0;
                } else if (id) {
                    const notification = notifications.value.find(n => n.id == id);
                    if (notification) {
                        notification.new = false;
                        notificationsCount.value -= 1;
                    }
                }
            }
        };

        const moreLessInfo = (notification: Notification, showMore: boolean) => {
            notification.opened = showMore ?? !notification.opened;
            if (notification.opened && notification.new) notificationMarkAsReaded(notification.id, false);
        };

        const sendOrderMessage = async (notification: Notification) => {
            if (!notification.replyMessage || !notification.orderId) return false;
            const apiResult = await api.SendOrderMessage(notification.orderId, notification.replyMessage);
            if (apiResult.data?.error) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.data?.error
                });
                return false;
            }
            return true;
        };

        const sendChatMessage = async (notification: Notification) => {
            if (!notification.replyMessage || !notification.indexUserId || !notification.productId) return false;
            const apiResult = await api.SendChatMessage(
                notification.productId,
                notification.orderQuoteId,
                notification.indexUserId,
                notification.replyMessage
            );
            if (apiResult.hasValidationError) {
                const message = apiResult
                    .validationErrorsList()
                    ?.map(x => `Field '${x.key}': ${x.msg}`)
                    ?.join('\n');
                swal.fire({
                    icon: 'error',
                    text: message
                });
                return false;
            }
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return false;
            }
            return true;
        };

        const sendMessage = async (notification: Notification) => {
            swal.showLoading();
            let sendingResult = false;
            if (notification.type == NotificationType.NewIndexChatMsg) {
                sendingResult = await sendChatMessage(notification);
            } else {
                sendingResult = await sendOrderMessage(notification);
            }

            if (!sendingResult) return;

            const foundNotificationReplies = repliedMessagesNotification.value.find(x => x.id == notification.id);
            if (foundNotificationReplies) {
                foundNotificationReplies.repliedMessages.push(notification.replyMessage);
            } else {
                const newRepliedMessage = new RepliedNotification();
                newRepliedMessage.id = notification.id;
                newRepliedMessage.repliedMessages.push(notification.replyMessage);
                repliedMessagesNotification.value.push(newRepliedMessage);
            }
            notification.replyMessage = '';
            swal.close();
        };

        const closeOpenedNotifications = async () => notifications.value.forEach(n => (n.opened = false));

        function changeNotificationCondition(status: boolean) {
            generalStore.commit('updateNotificationCondition', status);
        }

        const closeNotificationMenu = async (e: any) => {
            if (e.target.classList[0] == 'notifications-wrapper') {
                changeNotificationCondition(false);
                // isNotificationMenuExpand.value = false;
                closeOpenedNotifications();
                document.getElementById('notifications-list')?.scrollTo(0, 0);
            }
        };

        onBeforeUnmount(async () => {
            const notificationsList = document.getElementById('notifications-list');
            if (notificationsList) notificationsList.removeEventListener('scroll', handleScroll);
        });

        onMounted(async () => {
            const notificationsList = document.getElementById('notifications-list');
            if (notificationsList) notificationsList.addEventListener('scroll', handleScroll);
            await getNotifications('', '');
        });
        function checkReplyMessage(id: string) {
            const founNotification = notifications.value.find(x => x.id == id);
            if (!founNotification) {
                return;
            }
            if (founNotification.replyMessage.length > 255) {
                founNotification.replyMessage = founNotification.replyMessage.substring(0, 255);
            }
        }
        return {
            removemillis,
            moment,
            notifications,
            notificationsCount,
            locale,
            isNotificationMenuExpand,
            notificationMarkAsReaded,
            moreLessInfo,
            sendMessage,
            OrderStatusColors,
            OrderStatus,
            getCurrencySymbol,
            closeNotificationMenu,
            closeOpenedNotifications,
            changeNotificationCondition,
            repliedMessagesNotification,
            checkReplyMessage,
            productDetailSections
        };
    }
};
