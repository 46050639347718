
import { generalStore } from '@/store';
import NavBar from '@/components/NavBar.vue';
import { computed, onMounted, inject, ref } from 'vue';
import router from '@/router';
import swal from 'sweetalert2';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/Api';
import q from 'q';

import CreateBulkInvoicesReportModal from '@/components/Order/CreateBulkInvoicesReportModal.vue';
import RemindersSnoozePopup from '@/components/CrmCustomers/RemindersSnoozePopup.vue';

export default {
    components: {
        NavBar,
        CreateBulkInvoicesReportModal,
        RemindersSnoozePopup
    },
    setup() {
        const createBulkInvoicesReport = ref<InstanceType<typeof CreateBulkInvoicesReportModal>>();
        const emitter: any = inject('emitter');
        const { t } = useI18n();
        const height = computed<string>(() => router.currentRoute.value.meta.height);
        const routeAdditionalClass = computed<string>(() => router.currentRoute.value.meta.class);
        const backgroundColor = computed<string>(() => router.currentRoute.value.meta.backgroundColor);
        const routeName = computed<string>(() => router.currentRoute.value.meta.header);
        const modalAlerts = computed(() => generalStore.state.modalAlerts);
        const lastCreateBulkInvoicesEntityId = computed(() => generalStore.state.lastCreateBulkInvoicesEntityId);
        const lastCreateBulkInvoicesTaskId = computed(() => generalStore.state.lastCreateBulkInvoicesTaskId);
        const lastCreateBulkInvoicesTaskFailed = computed(() => generalStore.state.lastCreateBulkInvoicesTaskFailed);
        function handleScroll() {
            const app = document.getElementById('main-content');
            const layoutHeader = document.getElementById('layout-header');
            const NavBar = document.getElementById('main-navbar');
            if (app && layoutHeader && NavBar) {
                if (app.scrollTop + app.clientHeight >= app.scrollHeight) {
                    emitter.emit('isBottom');
                }
                // const limit = layoutHeader.clientHeight - 160;
                if (app.scrollTop > 5) {
                    if (!NavBar.classList.contains('not-transition')) {
                        NavBar.classList.add('not-transition');
                    }
                } else {
                    if (NavBar.classList.contains('not-transition')) {
                        NavBar.classList.remove('not-transition');
                    }
                }
            }
        }
        async function showBulkInvoicesReportModal() {
            if (!lastCreateBulkInvoicesEntityId.value) {
                return;
            }
            swal.showLoading();
            const apiPromise = api.GetBulkInvoicesHistoryInfo(lastCreateBulkInvoicesEntityId.value);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                createBulkInvoicesReport.value?.open(apiResult.data.bulkInvoicesInfo);
                generalStore.commit('updateCreateBulkInvoicesEntityId', null);
            }
            swal.close();
        }
        function deleteAlert() {
            generalStore.commit('removeCreateBulkInvoicesTaskFailed');
        }
        const onMountedCall = async () => {
            const app = document.getElementById('main-content');
            if (app) {
                app.addEventListener('scroll', handleScroll);
            }

            if (router.currentRoute.value.name == 'home') {
                for (const modalAlert of modalAlerts.value) {
                    switch (modalAlert.status) {
                        case 1:
                            {
                                const swalResult = await swal.fire({
                                    html: t('modal-alert.products-have-no-slots', { calendarUrl: modalAlert.buttonUrl }),
                                    customClass: {
                                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                                    },
                                    buttonsStyling: false,
                                    showCancelButton: false,
                                    showDenyButton: true,
                                    showConfirmButton: false,
                                    denyButtonText: t('button.cancel')
                                });

                                if (swalResult.isDenied) {
                                    await api.cancelModalAlert(modalAlert.id);
                                }
                            }
                            break;
                    }
                }
            }
        };
        onMounted(onMountedCall);
        return {
            routeName,
            generalStore,
            height,
            routeAdditionalClass,
            backgroundColor,
            lastCreateBulkInvoicesTaskFailed,
            lastCreateBulkInvoicesTaskId,
            lastCreateBulkInvoicesEntityId,
            showBulkInvoicesReportModal,
            createBulkInvoicesReport,
            deleteAlert
        };
    }
};
