
import { defineComponent, ref } from 'vue';
import OverlayModal from '../Calendar/OverlayModal.vue';
import { BulkInvoicesInfo } from '@/models/Accounting';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';

export default defineComponent({
    components: { OverlayModal },
    name: 'CreateBulkInvoicesReportModal',
    async setup() {
        const isOpen = ref(false);
        const bulkInvoicesInfo = ref(new BulkInvoicesInfo());
        function open(data: BulkInvoicesInfo) {
            bulkInvoicesInfo.value = data;
            isOpen.value = true;
        }
        function downloadPdf() {
            if (!bulkInvoicesInfo.value.pdfReportUrl) {
                return;
            }
            const link = document.createElement('a');
            link.href = bulkInvoicesInfo.value.pdfReportUrl;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        async function checkPdf() {
            swal.showLoading();
            const apiPromise = api.GetBulkInvoicesHistoryInfo(bulkInvoicesInfo.value.id);
            await q.delay(400);
            const apiResult = await apiPromise;
            if (apiResult.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: apiResult.errorMessage
                });
                return;
            }
            if (apiResult.data) {
                bulkInvoicesInfo.value.pdfReportUrl = apiResult.data.bulkInvoicesInfo.pdfReportUrl;
            }
            swal.close();
        }
        return {
            open,
            isOpen,
            bulkInvoicesInfo,
            downloadPdf,
            checkPdf
        };
    }
});
